<template>
  <div class="home" :style="`background: ${$AppData.config.appPageInfoList.filter(item => item.code === 'HomeFragment')[0]?.backgroundColor || '#fff'} url(${$AppData.config.backgroundImgUrl}) 0 0/100% auto no-repeat;`">
    <div class="page-title">{{appName}}</div>
    <van-swipe class="home-swipe" :autoplay="3000" :width="bannerList.length > 1 ? 350 : 'auto'" lazy-render>
      <van-swipe-item v-for="item in bannerList" :key="item.id">
        <img v-if="item.type === 0" :src="item.bannerImgUrl" @click="goToArticle(item)" />
        <a v-else-if="item.type === 1" :href="item.content" target="_blank">
          <img :src="item.bannerImgUrl" />
        </a>
        <img v-else-if="item.type === 2" :src="item.bannerImgUrl" @click="goToPage({code: item.content, name: item.name })" />
        <template v-else-if="item.type === 4">
          <img v-if="jsBrige" :src="item.bannerImgUrl" @click="goToIHash" />
          <a v-else href="/farmer-api/egg/login" target="_blank">
            <img :src="item.bannerImgUrl" />
          </a>
        </template>
        <img v-else :src="item.bannerImgUrl" />
      </van-swipe-item>
    </van-swipe>
    <div class="home-title">
<!--      <div class="line"></div>-->
      <p>{{$t('title.market')}}</p>
    </div>
    <pagelist page="HomeFragment" />
    <articlepopup :show="showArticle" :article="article" @hide="showArticle = false" />
    <authorizationdialog
      :show="showAuthorizationDialog"
      :token="authorizationToken"
      :appInfo="authorizationInfo"
      @hide="showAuthorizationDialog = false"
    />
    <van-dialog v-model:show="showAnnouncement" theme="round-button" :confirmButtonColor="$primary" :confirm-button-text="$t('button.confirm')">
      <template #default>
        <img src="/images/light.png" />
        <div>{{announcement}}</div>
      </template>
    </van-dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Swipe, SwipeItem, NoticeBar, Dialog } from 'vant';
import moment from 'moment';
import i18n from '@/assets/lang/index';
import pagelist from '@/components/PageList';
import articlepopup from '@/components/Article';
import authorizationdialog from '@/components/AuthorizationDialog';
import { queryBannerList, queryCommissionCarousel, queryAccountInfo, queryAuthorizationInfo } from "@/api";


export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [NoticeBar.name]: NoticeBar,
    pagelist,
    articlepopup,
    authorizationdialog,
  },
    
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      bannerList: [],
      commissionCarousel: [],
      announcement: '',
      showAnnouncement: false,
      article: {},
      showArticle: false,
      authorizationToken: '',
      authorizationInfo: '',
      showAuthorizationDialog: false,
      jsBrige: window.jsBridge,
    };
  },
  
  mounted() {
    const search = window.location.search.split('?')[1];
    const urlString = search ? search.split('&') : [];
    const urlParam = {};
    for(var i = 0; i < urlString.length; i++) {
      const _param = urlString[i].split('=');
      urlParam[_param[0]] = _param[1];
    }
    if (urlParam.page && urlParam.page === 'authorization') {
      localStorage.setItem('authorizationToken', urlParam.authorizationToken);
      window.location.search = '';
    }
    
    this.fetchBannerList();
    this.fetchCommissionCarousel();
    this.fetchAccountInfo((announcement) => {
      const today = moment().format('YYYY-MM-DD');
      const lastDate = localStorage.getItem('announcementDate');
      const lastAnnouncement = localStorage.getItem('announcementContent');
      if (announcement && (today !== lastDate || announcement !== lastAnnouncement)) {
        this.announcement = announcement;
        this.showAnnouncement = true;
        localStorage.setItem('announcementDate', today);
        localStorage.setItem('announcementContent', announcement);
      }
      let authorizationToken = localStorage.getItem('authorizationToken');
      if (authorizationToken) {
        this.authorizationToken = authorizationToken;
        this.fetchAuthorizationInfo();
      }
    });
    
  },

  methods: {
    fetchBannerList() {
      queryBannerList({}).then(res => {
        this.bannerList = res.data || [];
      });
    },
    fetchCommissionCarousel() {
      queryCommissionCarousel({}).then(res => {
        this.commissionCarousel = res.data || [];
      });
    },
    fetchAccountInfo(callback) {
      queryAccountInfo().then(res => {
        let accountInfo = res.data || {};
        callback && callback(accountInfo.homepageAnnouncement);
      });
    },
    fetchAuthorizationInfo() {
      queryAuthorizationInfo({
        token: this.authorizationToken,
      }).then(res => {
        this.authorizationInfo = res.data || {};
        if(this.authorizationInfo) {
          this.showAuthorizationDialog = true;
        } else {
          localStorage.removeItem('authorizationToken');
        }
      });
    },
    goToArticle(item) {
      if(this.$AppData.config.themeType) {
        this.showArticle = true;
        this.article = item;
      } else {
        localStorage.setItem('article', JSON.stringify(item));
        this.$router.push('article');
      }
    },
    goToIHash() {
      if (window.jsBridge) {
        window.jsBridge.invokeNative('openWebView', `${window.location.origin}/farmer-api/egg/login`, '');
      }
    },
  },
};
</script>

<style lang="less">
.home {
  min-height: 100vh;
  padding-bottom: 50px;
  box-sizing: border-box;
  .page-title {
    padding: 10px 16px;
    line-height: 60px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    // margin-bottom: 10px;
    // background-color: #F9FAFA;
  }
  &-swipe {
    height: 220px;
    padding-left: 20px;
    box-sizing: border-box;
    img {
      display: block;
      width: calc(100% - 12px);
      margin: 0 6px;
      height: 190px;
      background: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      box-shadow:0px 0px 14px rgba(0, 0, 0, 0.16);
    }
  }
  .home-title {
    display: flex;
    align-items: center;
    line-height: 20px;
    margin-top: 6px;
    padding: 6px 20px;
    font-size: 18px;
    font-weight: 700;
    color: var(--van-primary-color);
    background: @background-color;
    .line {
      width:3px;
      height:17px;
      background: @light-primary-color;
      border-radius:2px;
      margin-right: 8px;
    }
  }
  .van-dialog {
    padding-top: 30px;
    .van-dialog__content {
      max-height: 45vh;
      padding: 0 20px;
      overflow-y: scroll;
      text-align: left;
      white-space: pre-line;
      img {
        display: block;
        width: 42px;
        margin: 0 auto 20px;
      }
    }
    .van-dialog__confirm {
      min-width: 140px;
      padding: 0 32px;
      flex: 0;
      margin: 20px auto;
    }
  }
}
</style>
