<template>
  <van-popup 
    v-model:show="show" 
    position="bottom"
    round
    :style="{ height: '80%' }"
    :close-on-click-overlay="false"
    @click-overlay="close"
  >
    <div class="article-popup">
      <div class="article-title">{{article.title}}</div>
      <div v-show="article.timeStr" class="article-time">{{article.timeStr}}</div>
      <div class="article-content"><span>{{article.content}}</span></div>
    </div>
  </van-popup>
</template>

<script>
  
export default {
  name: 'Article',
  props: {
    show: Boolean,
    article: Object,
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('hide')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.article-popup {
  padding: 16px 16px 50px;
  text-align: center;
  .article-title {
    margin-top: 20px;
    line-height:20px;
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 700;
  }
  .article-time {
    color: #a5a5a5;
    font-size: 16px;
    font-weight: 700;
    margin: 20px;
  }
  .article-content {
    padding: 0 10px;
    color:#a5a5a5;
    font-size:14px;
    line-height:22px;
    text-align: left;
    span {
      white-space: pre-wrap;
    }
  }
}
</style>