<template>
  <van-popup v-model:show="show" round position="bottom" :close-on-click-overlay="false">
    <div class="authorization-dialog">
      <!-- <div class="title">{{appInfo.name}}</div> -->
      
      <div class="app-name">
        <van-image class="app-icon" :src="appInfo.imgUrl" />
        <span>{{appInfo.name}}</span>
        <span class="extra">{{$t('field.request')}}</span>
      </div>
      <div class="authorization-tip">{{$t('notify.getAuth')}}</div>
      <div class="authorization-list">
        <p v-for="item in appInfo.authorize" class="info-text">{{item}}</p>
      </div>
      <div class="btn-bar">
        <van-button type="danger" plain round size="small" @click="close">{{$t('common.cancel')}}</van-button>
        <van-button type="primary" round size="small" @click="setAgree">{{$t('button.authorization')}}</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { authorizationAgree } from "@/api";
  
export default {
  name: 'AuthorizationDialog',
  props: ['show', 'token', 'appInfo'],
  data() {
    return {};
  },
  methods: {
    setAgree() {
      authorizationAgree({
        token: this.token,
      }).then(res => {
        this.close();
      });
    },
    close() {
      localStorage.removeItem('authorizationToken');
      this.$emit('hide');
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.authorization-dialog {
  position: relative;
  padding: 32px 24px 48px;
  font-size: 16px;
  text-align: left;
  box-sizing: border-box;
  .app-name {
    margin-bottom: 24px;
    font-size: 18px;
    color: #666;
    .app-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 6px;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      line-height: 24px;
      vertical-align: middle;
      font-weight: 500;
      &.extra {
        margin-left: 8px;
        font-size: 16px;
      }
    }
  }
  .authorization-tip {
    font-size: 18px;
    font-weight: 500;
  }
  .authorization-list {
    margin-top: 16px;
    margin-left: 12px;
    .info-text {
      line-height: 36px;
    }
  }
  .btn-bar {
    display: flex;
    margin-top: 32px;
    justify-content: space-between;
    .van-button {
      flex: 1;
      margin: 0 20px;
      height: 36px;
      line-height: 34px;
      text-align: center;
      font-size: 16px;
    }
  }
}
</style>
